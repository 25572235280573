// angular import
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { LoginClass } from './login.class';
import { LoginService } from '../../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterModule, FormsModule, CommonModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoading: boolean = false;
  formData: LoginClass;

  constructor(
    private LoginService: LoginService, 
    private toastr: ToastrService, 
    private router: Router
  ) {
    this.formData = new LoginClass();
  }

  ngOnInit(): void {
    if (localStorage.getItem('overview-userLogin') !== null) this.router.navigateByUrl('/home');
  }

  login(data) {
    this.isLoading = true;

    this.LoginService.login(this.formData, data => {
      if (data?.result) {
        localStorage.setItem('overview-userLogin', JSON.stringify(data.result[0]));
        this.router.navigateByUrl('/home');
      } else {
        this.toastr.error('Usuario o Contraseña Incorrectos.');
        this.isLoading = false;
      }
    });
  }

}