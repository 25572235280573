import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP } from './constants';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  
  Subdominio = APP.SUBDOMINIO;
  Applicacion = APP.APP;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requests = request.clone({ headers: request.headers.set('subdominio', this.Subdominio).set('app', this.Applicacion) });
    return next.handle(requests);
  }
  
}
